/*  ==========================================================================
    App Styles

    Configuration     - Configuration of Priva Styles
    Main              - Priva Styles
    Overrides         - Temporary Design System overrides

    DO      -   Divide styling into logical semantic folders and partials.
            -   Try to avoid adding unstructured "shame" styling.

    DON'T   -   Add any style declarations to this file.
            -   Add @import statements to this file.
    ========================================================================== */

/* Configuration */
@import 'configuration/configuration';

/* Main */
@import 'priva';

/* Overrides */
@import 'overrides/overrides';

/* Dependencies */
@import 'highlight.js/styles/gml.min.css';
@import 'nouislider/dist/nouislider.min.css';
@import '~@angular/cdk/overlay-prebuilt.css';

/* Project */
@import 'app/app.scss';
