@import 'node_modules/@priva/styles/lib/sass/configuration/variables';
@import 'node_modules/@priva/styles/lib/sass/configuration/mixins';

/*  ==========================================================================
    List
========================================================================== */

.list {
    $block: &;

    &-action {
        @include focus-visible {
            background-color: $color-active-background;
        }
    }

    /* Size
    ========================================================================== */

    &--size-xs {
        #{$block}-action {
            @include focus-visible {
                padding-left: $list-size-xs-item-padding-v;
            }
        }
    }

    &--size-sm {
        #{$block}-action {
            @include focus-visible {
                padding-left: $list-size-sm-item-padding-v;
            }
        }
    }

    &--size-md {
        #{$block}-action {
            @include focus-visible {
                padding-left: $list-size-md-item-padding-v;
            }
        }
    }
}
