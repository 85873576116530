@import 'node_modules/@priva/styles/lib/sass/configuration/variables';
@import 'node_modules/@priva/styles/lib/sass/configuration/mixins';

priva-form-group {
    .label-container {
        display: flex;
    }

    .label-attribute {
        margin-left: grid-type-unit(1);
    }
}
